import { Injectable, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../app-config.module';
import { ContactRequest } from '../domain-classes';

@Injectable()
export class ContactService {
    public request = new ContactRequest();

    constructor(
        private http: HttpClient,
        private date: DatePipe,
        @Inject(APP_CONFIG) private config: AppConfig
    ) { }

    post(request: ContactRequest): Promise<string> {
        request.timeStamp = this.date.transform(new Date(), 'EEE MMM d, yyyy h:mm aaa');
        return this.http.post<any>(this.config.baseUrl + 'contacts', request)
            .toPromise()
            .then(resp => {
                return resp;
            })
            .catch(this.handlePromiseError);
    }

    private handlePromiseError(e: any) {
        return Promise.reject(e);
    }
}
