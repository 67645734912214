import { Injectable, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../app-config.module';
import { OBTViewModel, OnlineBookingRequest } from '@app/domain';

@Injectable()
export class OnlineBookingService {
    public request = new OnlineBookingRequest();
    public maxPlayers: number = 1;

    constructor(
        private http: HttpClient,
        private date: DatePipe,
        @Inject(APP_CONFIG) private config: AppConfig
    ) { }

    getOnlineTimes(date: Date): Promise<Array<OBTViewModel>> {
        return this.http.get<Array<OBTViewModel>>(this.config.baseUrl + 'onlinebooking?date=' + date.toDateString())
            .toPromise()
            .then(resp => {
                return this.addDate(resp);
            })
            .catch(this.handlePromiseError);
    }

    getComingimes(date: Date, count: number): Promise<Array<OBTViewModel>> {
        return this.http.get<Array<OBTViewModel>>(this.config.baseUrl + 'onlinebooking/coming?date=' + date.toDateString() + '&count=' + count)
            .toPromise()
            .then(resp => {
                return this.addDate(resp);
            })
            .catch(this.handlePromiseError);
    }

    post(request: OnlineBookingRequest): Promise<string> {
        return this.http.post<any>(this.config.baseUrl + 'onlinebooking', request)
            .toPromise()
            .then(resp => {
                return resp;
            })
            .catch(this.handlePromiseError);
    }

    getTest(): Promise<any> {
        return this.http.get<any>(this.config.baseUrl + 'onlinebooking/test?date=' + (new Date()).toUTCString())
            .toPromise()
            .then(resp => {
                return resp;
            })
            .catch(this.handlePromiseError);
    }

    private addDate(data:OBTViewModel[]): OBTViewModel[]{
        if (null == data || data.length == 0) {
            return [];
        }
        for (var ii = 0; ii < data.length; ii++) {
            data[ii].date = new Date(data[ii].timeStamp);
        }
        return data;
    }
    private handlePromiseError(e: any) {
        return Promise.reject(e);
    }
}
