import { NgModule, Component } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreditCardDirectivesModule } from 'angular-cc-library';


import { AuthRedirectComponent } from './auth/redirect.component';
import { UnauthorizedComponent } from './auth/unauthorized.component';
import { LogService } from './services/log.service';
import { ContactService } from './services/contact.service';
import { OnlineBookingService } from './services/online-booking.service';
import { GiftCertificateService } from './services/gift-certificate.service';
import { PaymentInfoComponent } from './payment/payment-info/payment-info.component';
import { PhoneControlComponent } from './phone-control/phone-control.component';
import { PhonePipe } from './pipes/phone-pipe';


@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        CreditCardDirectivesModule
    ],
    exports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CreditCardDirectivesModule,
        PaymentInfoComponent,
        PhoneControlComponent,
        PhonePipe
    ],
    declarations: [
        AuthRedirectComponent,
        UnauthorizedComponent,
        PaymentInfoComponent,
        PhoneControlComponent,
        PhonePipe
    ],
    providers: [
        DatePipe,
        LogService,
        ContactService,
        OnlineBookingService,
        GiftCertificateService
    ]
})
export class AppSharedModule { }

