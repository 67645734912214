import { Injectable, Inject, Component, OnInit, OnDestroy } from '@angular/core';
//import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { APP_CONFIG, AppConfig } from '@app/config';
import { UserManager, UserManagerSettings, User } from 'oidc-client';

//import { APP_CONFIG, AppConfig } from '@app/config';

export class UserInfo {
    constructor(
        private user: User
    ) {
    }

    get isAdmin(): boolean { return this.containsRole('Admin'); }
    get isEmployee(): boolean { return this.containsRole('Employee'); }

    get name(): string {
        if (!this.user || !this.user.profile['fullName']) { return 'not logged in'; }
        return this.user.profile['fullName'];
    }

    // get first(): string {
    //     if (!this.user || !this.user.profile['firstName']) { return 'not logged in'; }
    //     return this.user.profile['firstName'];
    // }

    // get last(): string {
    //     if (!this.user || !this.user.profile['lastName']) { return 'not logged in'; }
    //     return this.user.profile['lastName'];
    // }

    // get email(): string {
    //     if (!this.user || !this.user.profile['email']) { return 'not logged in'; }
    //     return this.user.profile['email'];
    // }

    private containsRole(name: string): boolean {
        let claimType = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
        if (!this.user || !this.user.profile[claimType]) { return false; }
        var roles = this.user.profile[claimType];
        if (Array.isArray(roles)) {
            return roles.indexOf(name) > -1;
        }
        else {
            return roles == name;
        }
    }
};

@Injectable()
export class AuthService implements OnInit, OnDestroy {
    private manager: UserManager = null;
    private user: User;
    public current: UserInfo = new UserInfo(null);
    private trash = '';
    constructor(
        private router: Router,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {
        var oidcConfig = {
            authority: config.OIDConfig.stsServer,
            client_id: config.OIDConfig.client_id,
            redirect_uri: config.OIDConfig.redirect_url,
            response_type: "id_token token",
            scope: config.OIDConfig.scope,
            post_logout_redirect_uri: config.OIDConfig.post_logout_redirect_uri,
            automaticSilentRenew: true,
            silent_redirect_uri: config.OIDConfig.silent_redirect_uri,
            loadUserInfo: false
        };
        
        this.manager = new UserManager(oidcConfig);
    }

    ngOnInit() {

    }

    loadUser(): Promise<UserInfo> {
        return new Promise((resolve, reject)=> {
            this.manager.getUser().then((user) => {
                if (user) {
                    this.setUser(user);
                    resolve(this.current);
                }
                else {
                    this.startAuthentication();
                }
            });
        });
    }
    
    isLoggedIn(): boolean {
        return this.user != null && !this.user.expired;
    }

    getClaims(): any {
        return this.user.profile;
    }

    startAuthentication(): Promise<void> {
        return this.manager.signinRedirect();
    }

    completeAuthentication(): Promise<void> {
        return this.manager.signinRedirectCallback().then(user => {
            this.setUser(user);
            let url = localStorage.getItem("lastURL");
            if (!url) {
                if (this.current.isAdmin || this.current.isEmployee) {
                    this.router.navigateByUrl('internal');
                } else {
                    this.router.navigateByUrl('home');
                }
            }
            else {
                this.router.navigateByUrl(url);
            }
        });
    }

    login() {
        this.manager.signinRedirect();
    }

    getToken(): string {
        return this.user ? this.user.access_token + this.trash : '';
    }

    logout() {
        this.manager.signoutRedirect().then((r) => {
            this.current = new UserInfo(null);
        })
    }

    setTokenTrash(t: string) {
        this.trash = t; // just a test method for trashing the auth token
    }

    private setUser(user: User) {
        this.user = user;
        this.current = new UserInfo(user);
    }

    ngOnDestroy(): void {
        // this.isAuthorizedSubscription.unsubscribe();
    }
}
