import { Component, OnInit, Input, EventEmitter, Output, forwardRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CreditCardValidator, CreditCardFormatDirective } from 'angular-cc-library';
import { PaymentValidators } from '../payment-validators';
import { CardAddress, CardInfo, PaymentInfo, ACHInfo } from '../../domain-classes';


declare var $: any;

@Component({
    selector: 'payment-info',
    templateUrl: './payment-info.component.html',
    styleUrls: ['./payment-info.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PaymentInfoComponent),
            multi: true
        }
    ]
})
export class PaymentInfoComponent implements OnInit {
    @ViewChild(CreditCardFormatDirective, { static: true }) ccf: any;
    @Output() valueChange = new EventEmitter<PaymentInfo>();
    private _model: PaymentInfo = new PaymentInfo();
    set model(val: PaymentInfo) {
        if (this._model != val) {
            this._model = val;
            this.propagateChange(val);
            this.valueChange.emit(val);
            this.resetForm();
        }
    }
    get model(): PaymentInfo { return this._model; }

    public form: FormGroup;

    constructor(
        private fb: FormBuilder
    ) {
        this.createForm();

    }

    createForm() {
        this.form = this.fb.group({
            tenderType: [''],
            creditCardInfo: this.fb.group(new CardInfo()),
            achInfo: this.fb.group(new ACHInfo())
        });
        this.form.get('creditCardInfo.number').setValidators([Validators.required, <any>CreditCardValidator.validateCCNumber,
            new PaymentValidators(['visa', 'mastercard', 'amex', 'discover']).validateCCType]);
        this.form.get('creditCardInfo.expiry').setValidators([Validators.required, <any>CreditCardValidator.validateExpDate]);
        this.form.get('creditCardInfo.cvc').setValidators([Validators.required, PaymentValidators.validateSecurityCode]);
        //this.form.get('creditCardInfo.zip').setValidators([Validators.required, PaymentValidators.validatePostalCode]);
        //this.form.get('creditCardInfo.street').setValidators([Validators.required]);
        //this.form.get('achInfo.routingNumber').setValidators([Validators.required, PaymentValidators.validateRoutingNumber]);
        //this.form.get('achInfo.accountNumber').setValidators([Validators.required, PaymentValidators.validateAccountNumber]);
        //this.form.get('achInfo.name').setValidators([Validators.required]);
        //this.form.get('achInfo.phone').setValidators([Validators.required, PaymentValidators.validateACHPhone]);

        this.form.valueChanges
            .subscribe(data => this.onValueChanged(data));

        this.onValueChanged(); // (re)set validation messages now
    }

    // ControlValueAccessor related....................
    propagateChange = (_: any) => { };

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() { }

    writeValue(value: any) {
        if (value !== undefined  && null != value) {
            this.model = value;
        }
    }
    //.................................................
    ngOnInit() {
    }

    ngOnChanges() {
        this.resetForm();
    }

    private resetForm() {
        this.form.reset(this.model);
        this.ccf.reFormatCardNumber();
        this.ccf.setCardType();
        this.onValueChanged(); // run again to set card type and last4
    }

    onValueChanged(data?: any) {
        if (!this.form) { return; }
        const form = this.form;

        for (const field in this.formErrors) {
            // clear previous error message (if any)
            this.formErrors[field] = '';

            const control = form.get(field);
            if (control && control.dirty && !control.valid) {
                const messages = this.validationMessages[field];
                for (const key in control.errors) {
                    this.formErrors[field] += messages[key] + ' ';
                }
            }
        }

        if (this.formErrors['creditCardInfo.cvc'] != 'creditCardInfo.cvc') {
            let e = $('#ccNumber');
            let ctrl = form.get('creditCardInfo.cvc');
            if (ctrl.dirty && e.hasClass('amex') && ctrl.value.length != 4) {
                this.formErrors['creditCardInfo.cvc'] = 'Amex must have 4 digit cvc';
            } else if (ctrl.dirty && !e.hasClass('amex') && ctrl.value.length != 3) {
                this.formErrors['creditCardInfo.cvc'] = 'Must have 3 digit cvc';
            }
        }

        let parts = this.form.value.creditCardInfo.expiry.split('/');
        this.form.value.creditCardInfo.expMonth = parts[0];
        this.form.value.creditCardInfo.expYear = parts[1];

        //  Transfer values from the Form Model to the Data Model...
        this._model.tenderType = this.form.value.tenderType;
        this._model.achInfo = this.form.value.achInfo;
        this._model.creditCardInfo = this.form.value.creditCardInfo;
        if (this.model.tenderType == 'Credit') {
            this._model.valid = this.form.controls['creditCardInfo'].valid;
            let e = $('#ccNumber');
            let ctrl = form.get('creditCardInfo.cvv');
            if (e.hasClass('amex')) { this._model.cardType = "American Express"; }
            else if (e.hasClass('visa')) { this._model.cardType = "Visa"; }
            else if (e.hasClass('mastercard')) { this._model.cardType = "Master Card"; }
            else if (e.hasClass('discover')) { this._model.cardType = "Discover"; }
            else { this._model.cardType = ""; }
            let n = this._model.creditCardInfo.number;
            this._model.last4 = n.slice(n.length - 4, n.length);
        }

        if (this.model.tenderType == 'Debit') {
            this._model.valid = this.form.controls['achInfo'].valid;
        }
        this.writeValue(this._model);
    }

    formErrors = {
        'creditCardInfo.number': '',
        'creditCardInfo.expiry': '',
        'creditCardInfo.cvc': '',
        //'creditCardInfo.zip': '',
        //'creditCardInfo.street': '',
        //'achInfo.routing': '',
        //'achInfo.account': '',
        //'achInfo.name': ''
    };

    validationMessages = {
        'creditCardInfo.number': {
            'ccNumber': 'Valid credit card number is required.',
            'ccTypeNotAllowed': 'Credit card type not allowed.'
        },
        'creditCardInfo.expiry': {
            'expDate': 'Valid expiration date is required.'
        },
        'creditCardInfo.cvc': {
            'invalid': 'Security code must be 4 digits for AMEX, 3 digits otherwise.'
        },
        'creditCardInfo.zip': {
            'invalid': 'Postal code must be 6 digits.'
        },
        'creditCardInfo.street': {
            'required': 'Street address required.'
        },
        'achInfo.routing': {
            'invalid': 'Invalid routing number.'
        },
        'achInfo.account': {
            'invalid': 'Invalid account number.'
        },
        'achInfo.name': {
            'required': 'Account name required.'
        },
    };
}
