import { Component, OnInit, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@app/config';

@Component({
    selector: 'app-merchandise',
    templateUrl: './merchandise.component.html',
    styleUrls: ['./merchandise.component.scss']
})
export class MerchandiseComponent implements OnInit {

    constructor(
        @Inject(APP_CONFIG) private config: AppConfig
    ) { }

    ngOnInit() {
    }

}
