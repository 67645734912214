import { Injectable } from '@angular/core';
declare var $: any;

@Injectable()
export class MessageService {
    public waiting = false;
    public title = '';
    public message = '';
    private _handler: any;

    constructor(
    ) { 
        this._handler = this.emptyHandler; 
    }

    public wait(message: string, title: string) {
        this.title = title;
        this.message = message;
        this.waiting = true;
        this.show();
    }

    public info(message: string, title: string, handler: any = null) {
        this.title = title;
        this.message = message;
        this.waiting = false;
        this._handler = handler ? handler : this.emptyHandler;
        this.show();
    }

    public error(e: any, title: string) {
        this.title = "ERROR: " + title;
        if (e.error && e.error.Message){
            this.message = e.error.Message;
        }else{
            try {
                this.message = e.error.details.join();
            }
            catch (e) {
                this.message = JSON.stringify(e);
            }
        }
        this.waiting = false;
        this.show();
    }

    public show() {
        $('.popup-with-form').click();
    }

    public hide() {
        $('.mfp-close').click();
    }

    public closeHandler = () => {
        this._handler();
        this._handler = this.emptyHandler;
    }

    private emptyHandler = () => {}
}
