export class ContactRequest {
    name = '';
    email = '';
    subject = '';
    message = '';
    phone = '';
    timeStamp = '';
}

export class OBTViewModel {
    date: Date;
    timeStamp: string;
    guestRate: number;
    seniorRate: number;
    publicRate: number;
    specialRate: number;
    numberOfSlots: number;
}

export class OnlineBookingRequest {
    date: Date;
    timeStamp: string;
    numberOfSlots: number = 1;
    email: string = '';
    first: string = '';
    last: string = '';
    phone: string = '';
}

export class UserViewModel
{
    id: number = -1;
    displayName: string; 
    role: string = 'Employee';
    email: string = '';
    firstName: string = '';
    lastName: string = '';
    phoneNumber: string = '';
}

export class GiftCertificate {
    serialNumber = 0;
    originalAmount = 50;
    amountRemaining = 0;
    recipientName= '';
    recipientEmail= '';
    giftMessage= '';
    internalNotes= '';
}


export class RecipientInfo {
    recipientName = '';
    recipientEmail = '';
    giftMessage = '';
    amount:number;
}

export class PurchaserInfo {
    firstName = '';
    lastName = '';
    email = '';
}

export class GiftCertificateRequest {
    recipientName = '';
    recipientEmail = '';
    giftMessage = '';
    amount = 1;
    firstName= '';
    lastName= '';
    email= '';
    streetAddress= '';
    city= '';
    zip= '';
    token= '';
}

export class CardAddress {
    region = '';
    postalCode = '';
    streetAddress = '';
    country = "US";
    city = '';
}

export class CardTokenRequest {    
    expYear = "2020";
    expMonth= "00";
    address= new CardAddress();
    name = '';
    cvc = '';
    number = '';
}

export class CardInfo {
    expiry = '12/2020';
    expYear = "2020";
    expMonth = "00";
    cvc = '';
    number = '';

    public static getTestCard() {
        let card = new CardInfo();
        card.number = '4242424242424242';
        card.expiry = '02/2019';
        card.cvc = '110';
        return card;
    }

    public static getDeclinedCard() {
        let card = new CardInfo();
        card.number = '4100000000000001';
        card.expiry = '12/2020';
        card.cvc = '000';
        return card;
    }
}

export class ACHInfo {
    routingNumber = '';
    accountNumber = '';
    accountType = 'PERSONAL_CHECKING';  // or PERSONAL_SAVINGS or?
    name = '';
    phone = ''; // required, must be exactly 10 characters
}

export class PaymentInfo {
    tenderType: string = "Credit";
    cardType: string;
    last4: string;
    valid: boolean = false;
    creditCardInfo: CardInfo = new CardInfo();
    achInfo = new ACHInfo();
}
