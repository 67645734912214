import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-clga',
    templateUrl: './clga.component.html',
    styleUrls: ['./clga.component.scss']
})
export class ClgaComponent implements OnInit {
    public currentYear = new Date().getFullYear();
    constructor() { }

    ngOnInit() {
    }

}
