import { Component, OnInit, Inject } from '@angular/core';
import { routerTransition } from '../shared/router.animations';
import { APP_CONFIG, AppConfig } from '@app/config';
declare var $: any;
@Component({
    selector: 'app-public',
    templateUrl: './public.component.html',
    styleUrls: ['./public.component.scss'],
    animations: [ routerTransition ]
})
export class PublicComponent implements OnInit {
    public currentYear = new Date().getFullYear();
    constructor(
        @Inject(APP_CONFIG) private config: AppConfig
    ) { }

    getState(outlet) {
        return outlet.activatedRouteData.state;
    }

    ngOnInit() {
        $('.navbar-nav a.nav-link:not(.dropdown-toggle)').on('click', function(e){
            $('.navbar-collapse').collapse('hide');
        });
    }
}
