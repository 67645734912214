export const environment = {
    production: true,
    tokenUrl: 'https://sandbox.api.intuit.com/quickbooks/v4/payments/tokens',
    apiUrl: 'https://cedarlakegolf.com/api/',
    stsServer: 'https://cedarlakegolf.com',
    redirect_url: 'https://cedarlakegolf.com/auth',
    scope: 'openid profile api customProfile',
    post_logout_redirect_uri: 'https://cedarlakegolf.com/internal',
    silent_redirect_uri: 'https://cedarlakegolf.com/silent-refresh.html',
    client_id: 'ngClientPROD',
    ecommerce_url: 'https://cedarlakegolf.bigcartel.com/products'
};
