import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContactService } from '../../shared/services/contact.service';

declare var $: any;

export class MembershipRequest {
    id = 0;
    firstName = '';
    lastName = '';
    email = '';
    phone = '';
}


@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss']
})
export class MembershipComponent implements OnInit {
    public mr = new MembershipRequest();
    public type: string;
    constructor(
        public contact: ContactService,
        private router: Router
    ) { }

    ngOnInit() {
        $('.popup-with-form').magnificPopup({
            type: 'inline',
            preloader: false,
            focus: '#name',

            // When elemened is focused, some mobile browsers in some cases zoom in
            // It looks not nice, so we disable it:
            callbacks: {
                beforeOpen: function() {
                    if($(window).width() < 700) {
                        this.st.focus = false;
                    } else {
                        this.st.focus = '#name';
                    }
                }
            }
        });
    }

    show(membershipType: string) {
        this.contact.request.subject = membershipType  + ' Membership Inquiry';
        this.contact.request.message = "Please send me more detailed information about membership options";
        this.router.navigate(['/contact']);
        //this.type = membershipType;
        //$('.popup-with-form').click();
    }

    join() {
        $('.mfp-close').click();
    }

}
