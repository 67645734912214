import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppConfigModule } from './app-config.module';
import { AppRoutingModule } from './app.routing';
import { BsDatepickerModule, ButtonsModule, PopoverModule, TimepickerModule, TypeaheadModule, BsDropdownModule } from 'ngx-bootstrap';
import { PublicModule } from './public/public.module';

import { AppComponent } from './app.component';
import { MessageService } from '@app/services';
import { CanDeactivateGuard } from './shared/guards/can-deactivate.guard';
import { EmployeeGuard } from './shared/guards/employee.guard';
import { AuthInterceptor } from './shared/auth-interceptor';
import { DatesInterceptor } from './shared/guards/dates.interceptor';

import { AuthService } from './shared/services/auth.service';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        BsDatepickerModule.forRoot(),
        ButtonsModule.forRoot(),        
        PopoverModule.forRoot(),
        TimepickerModule.forRoot(),
        TypeaheadModule.forRoot(),
        BsDropdownModule.forRoot(),
        AppRoutingModule,
        AppConfigModule,
        PublicModule
    ],
    providers: [
        AuthService,
        MessageService,
        CanDeactivateGuard,
        EmployeeGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DatesInterceptor,
            multi: true
        }],
    bootstrap: [AppComponent]
})
export class AppModule { 
}
