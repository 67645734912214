import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmployeeGuard } from './shared/guards/employee.guard';
import { PublicComponent } from './public/public.component';
import { HomeComponent } from './public/home/home.component';
import { EventsComponent } from './public/events/events.component';
import { ContactComponent } from './public/contact/contact.component';
import { MembershipComponent } from './public/membership/membership.component';
import { NewsComponent } from './public/news/news.component';
import { CourseDetailsComponent } from './public/course-details/course-details.component';
import { ServicesComponent } from './public/services/services.component';
import { TeeTimesComponent } from './public/teetimes/teetimes.component';
import { GiftCertificatesComponent } from './public/gift-certificates/gift-certificates.component';
import { AuthRedirectComponent } from './shared/auth/redirect.component';
import { UnauthorizedComponent } from './shared/auth/unauthorized.component';

import { LessonsComponent } from './public/services/lessons/lessons.component';
import { OutingsComponent } from './public/services/outings/outings.component';
import { MerchandiseComponent } from './public/services/merchandise/merchandise.component';
import { ClgaComponent } from './public/services/clga/clga.component';
import { DiscountsComponent } from './public/services/discounts/discounts.component';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'internal', loadChildren: () => import('app/internal/internal.module').then(m => m.InternalModule), canLoad: [EmployeeGuard]},
    { path: 'auth', component: AuthRedirectComponent },
    { path: 'unauthorized', component: UnauthorizedComponent },
    {
        path: '', component: PublicComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', component: HomeComponent, data: { state: 'home'} },
            { path: 'events', component: EventsComponent, data: { state: 'events'} },
            { path: 'news', component: NewsComponent, data: { state: 'news'} },
            { path: 'course', component: CourseDetailsComponent, data: { state: 'course'} },
            { path: 'services', component: ServicesComponent, data: { state: 'services'}  },
            { path: 'membership', component: MembershipComponent, data: { state: 'membership'} },
            { path: 'contact', component: ContactComponent, data: { state: 'contact'}  },
            { path: 'teetimes', component: TeeTimesComponent, data: { state: 'teetimes'}  },
            { path: 'lessons', component: LessonsComponent, data: { state: 'lessons'}  },
            { path: 'outings', component: OutingsComponent, data: { state: 'outings'}  },
            { path: 'merchandise', component: MerchandiseComponent, data: { state: 'merchandise'}  },
            { path: 'clga', component: ClgaComponent, data: { state: 'clga'}  },
            { path: 'discounts', component: DiscountsComponent, data: { state: 'discounts' } },
            // { path: 'giftcertificates', component: GiftCertificatesComponent, data: { state: 'giftcertificates' } }
        ]
    },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
