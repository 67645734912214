import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'phone'
})
export class PhonePipe implements PipeTransform {
    transform(val, args) {
        if (null == val){return '';}
        val = val.trim().replace(/^\+/, '');
        val = val.replace(/[^0-9]/g, '').slice(0,10) + '          ';
        return '(' + val.slice(0,3) + ') ' + val.slice(3,6) + '-' + val.slice(6,10);
    }
}