import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
    public type: string;
    constructor(
        private router: Router
    ) { }

    ngOnInit() {
        $('.popup-with-form').magnificPopup({
            type: 'inline',
            preloader: false,
            focus: '#name',

            // When elemened is focused, some mobile browsers in some cases zoom in
            // It looks not nice, so we disable it:
            callbacks: {
                beforeOpen: function () {
                    if ($(window).width() < 700) {
                        this.st.focus = false;
                    } else {
                        this.st.focus = '#name';
                    }
                }
            }
        });
        //this.show('lessons');
    }

    navigate(route: string) {
        this.router.navigate([route]);
    }

    show(membershipType: string) {
        this.type = membershipType;
        $('.popup-with-form').click();
    }

    join() {
        $('.mfp-close').click();
    }

}
