import { Component, OnInit, OnChanges, ViewEncapsulation} from '@angular/core';
import { OnlineBookingService } from '@app/services';
import { OBTViewModel, OnlineBookingRequest } from '@app/domain';

declare var $: any;

@Component({
    selector: 'teetime-banner',
    templateUrl: './teetime-banner.component.html',
    styleUrls: ['./teetime-banner.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TeeTimeBannerComponent implements OnInit, OnChanges{
    public date: Date = new Date();
    public data: Array<OBTViewModel> = [];

    constructor(
        public obSvc: OnlineBookingService
    ) { 
    }
    
    ngOnChanges(e: any) {
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.obSvc.getComingimes(this.date, 8).then(data => {
            this.data = data;
        })
    }
}
