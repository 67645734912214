import { NgModule, InjectionToken } from '@angular/core';
import { environment } from '../environments/environment';

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export interface OIDConfig {
    stsServer: string,
    redirect_url: string;
    client_id: string;
    scope: any;
    post_logout_redirect_uri: string;
    silent_redirect_uri: string;
}

export class AppConfig {
    baseUrl: string;
    tokenUrl: string;
    OIDConfig: OIDConfig;
    courseLat: number;
    courseLong: number;
    ecommerce_url: string;
    roles: { super: string, admin: string, employee: string, user: string };
} 

export const APP_DI_CONFIG: AppConfig = {
    baseUrl: environment.apiUrl,
    tokenUrl: environment.tokenUrl, 
    courseLat: 33.7671744,
    courseLong: -83.921551,
    ecommerce_url: environment.ecommerce_url,
    OIDConfig: {
        stsServer: environment.stsServer,
        redirect_url: environment.redirect_url,
        client_id: environment.client_id,
        scope: environment.scope,
        post_logout_redirect_uri: environment.post_logout_redirect_uri,
        silent_redirect_uri: environment.silent_redirect_uri
    },
    roles: {
        super: "SystemAdmin",
        admin: "Admin",
        employee:  "Employee",
        user:  "User"
    }
};

@NgModule({
    providers: [{
        provide: APP_CONFIG,
        useValue: APP_DI_CONFIG
    }]
})
export class AppConfigModule {
  }
