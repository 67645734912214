import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { APP_CONFIG, AppConfig } from '../../app-config.module';
import { CardInfo, GiftCertificateRequest} from '../domain-classes';

@Injectable()
export class GiftCertificateService {
    constructor(
        private http: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig
    ) { }

    createToken(cardInfo: CardInfo): Promise<string> {
        let body = {
            card: {
                expYear: cardInfo.expYear,
                expMonth: cardInfo.expMonth,
                cvc: cardInfo.cvc,
                number: cardInfo.number
            }
        }
        return this.http.post<any>(this.config.tokenUrl, body)
                .toPromise()
                .then(resp => {
                    return resp.value;
                })
                .catch(this.handlePromiseError);
    }

    processGiftCertificates(request: GiftCertificateRequest): Promise<string> {
        return this.http.post<string>(this.config.baseUrl + 'payments/giftcertificate', request)
            .toPromise()
            .then(resp => {
                return resp;
            })
            .catch(this.handlePromiseError);
    }

    private handlePromiseError(e: any) {
        return Promise.reject(e);//JSON.stringify(e));
    }
}
