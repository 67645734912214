import { CreditCard } from 'angular-cc-library';

export class PaymentValidators {

    /**
     * Full set of allowed types:  'maestro', 'forbrugsforeningen', 'dankort', 'visa', 'mastercard', 'amex', 'dinersclub', 'discover', 'unionpay', 'jcb'
     */
    constructor(
        private allowedTypes: string[]
    ) {
    }

    validateCCType = (control) => {

        var num = control.value.toString().replace(/\s+|-/g, '');
        var card = CreditCard.cardFromNumber(num);
        if (!card) {
            return null;  // let the credit card format validator handle this
        }
        if (this.allowedTypes.indexOf(card.type) < 0) {
            return { 'ccTypeNotAllowed': true };
        }

        return null;
    };

    static validateSecurityCode = (control) => {
        if (/^\d{3,4}$/.test(control.value)) {
            return null;
        }
        return { 'invalid': true };
    };

    static validateACHPhone = (control) => {
        if (/^\d{10}$/.test(control.value)) {  // exactly 10 digits
            return null;
        }
        return { 'invalid': true };
    };

    static validatePostalCode = (control) => {
        // TODO this fails for zip codes starting with 0
        if (/^\d{6}$/.test(control.value)) {
            return null;
        }
        return { 'invalid': true };
    };

    static validateRoutingNumber = (control) => {
        // SOURCE:  https://github.com/DrShaffopolis/bank-routing-number-validator
        // testing resource:  https://developer.wepay.com/docs/articles/testing
        let ABARoutingNumberIsValid =  function(routingNumberToTest) {
            if (!routingNumberToTest) { //all 0's is technically a valid routing number, but it's inactive
                return false;
            }
            
            var routing = routingNumberToTest.toString();
            // WDD - why zero pad and THEN check the length is 9??
            while (routing.length < 9) {
                routing = '0' + routing; //I refuse to import left-pad for this
            }

            //gotta be 9  digits
            var match = routing.match("^\\d{9}$");
            if (!match) {
                return false;
            }

            //The first two digits of the nine digit RTN must be in the ranges 00 through 12, 21 through 32, 61 through 72, or 80.
            //https://en.wikipedia.org/wiki/Routing_transit_number
            const firstTwo = parseInt(routing.substring(0, 2));
            const firstTwoValid = (0 <= firstTwo && firstTwo <= 12)
                || (21 <= firstTwo && firstTwo <= 32)
                || (61 <= firstTwo && firstTwo <= 72)
                || firstTwo === 80;
            if (!firstTwoValid) {
                return false;
            }

            //this is the checksum
            //http://www.siccolo.com/Articles/SQLScripts/how-to-create-sql-to-calculate-routing-check-digit.html
            const weights = [3, 7, 1];
            var sum = 0;
            for (var i = 0; i < 8; i++) {
                sum += parseInt(routing[i]) * weights[i % 3];
            }

            return (10 - (sum % 10)) % 10 === parseInt(routing[8]);
        }

        return ABARoutingNumberIsValid(control.value) ? null : { 'invalid': true };
    }

    static validateAccountNumber = (control) => {
        // TODO this is really a guess and just checks its nine digits, not all zeros
        let AccountNumberIsValid = function (numberToTest) {
            if (!numberToTest) { //all 0's is technically a valid routing number, but it's inactive
                return false;
            }

            var routing = numberToTest.toString();
            // WDD - why zero pad and THEN check the length is 9??
            //while (routing.length < 9) {
            //    routing = '0' + routing; //I refuse to import left-pad for this
            //}

            //gotta be 9  digits
            var match = routing.match("^\\d{9}$");
            if (!match) {
                return false;
            }

            return true;

        }

        return AccountNumberIsValid(control.value) ? null : { 'invalid': true };
    };
}

