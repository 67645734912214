import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outings',
  templateUrl: './outings.component.html',
  styleUrls: ['./outings.component.scss']
})
export class OutingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
