import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RecipientInfo, PaymentInfo, PurchaserInfo, CardInfo, GiftCertificateRequest } from '../../shared/domain-classes';
import { GiftCertificateService } from '../../shared/services/gift-certificate.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
    selector: 'app-gift-certificates',
    templateUrl: './gift-certificates.component.html',
    styleUrls: ['./gift-certificates.component.scss']
})
export class GiftCertificatesComponent implements OnInit {
    public paymentInfo = new PaymentInfo();
    public recipientInfo = new RecipientInfo();
    public purchaserInfo = new PurchaserInfo();
    public selectionComplete = false;
    public paymentInfoComplete = false;
    public confirmed = false;
    public recipientForm: FormGroup;
    public purchaserForm: FormGroup;
    public msg: any = { waiting: false, error: false, message: '' };
    public inProgress = false;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private svc: GiftCertificateService
    ) {
        this.createForm();
        //this.selectionComplete = true;
        //this.paymentInfoComplete = true;
        //this.getTestCard();
        //this.getTestRecipient();
    }

    createForm() {
        this.recipientForm = this.fb.group({
            recipientName: [''],
            recipientEmail: [''],
            giftMessage: [''],
            amount: [''],
        });

        this.recipientForm.get('recipientName').setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(30)]);
        this.recipientForm.get('recipientEmail').setValidators([Validators.required, Validators.email]);
        this.recipientForm.get('giftMessage').setValidators([Validators.maxLength(500)]);
        this.recipientForm.get('amount').setValidators([Validators.required, Validators.min(1), Validators.max(1000)]);

        this.recipientForm.valueChanges
            .subscribe(data => this.onValueChanged(data));

        this.purchaserForm = this.fb.group(new PurchaserInfo());
        this.purchaserForm.get('firstName').setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(30)]);
        this.purchaserForm.get('lastName').setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(30)]);
        this.purchaserForm.get('email').setValidators([Validators.required, Validators.email]);

        this.purchaserForm.valueChanges
            .subscribe(data => this.onValueChanged(data));

        this.onValueChanged(); // (re)set validation messages now
    }

    ngOnInit() {
        $('.popup-with-form').magnificPopup({
            type: 'inline',
            preloader: false,
            focus: '#name',
            closeOnBgClick: true,

            // When elemened is focused, some mobile browsers in some cases zoom in
            // It looks not nice, so we disable it:
            callbacks: {
                beforeOpen: function () {
                    if ($(window).width() < 700) {
                        this.st.focus = false;
                    } else {
                        this.st.focus = '#name';
                    }
                }
            }
        });
    }

    ngOnChanges() {
        this.resetForm();
    }

    private resetForm() {
        this.recipientForm.reset(this.recipientInfo);
        this.purchaserForm.reset(this.purchaserInfo);
    }

    onValueChanged(data?: any) {
        if (!this.recipientForm) { return; }
        let form = this.recipientForm;

        // remove zeros
        var val = form.get('amount').value;
        if (val > 0 && val % 1 > 0) { form.get('amount').setValue(Math.floor(val)); }

        for (const field in this.formErrors) {
            // clear previous error message (if any)
            this.formErrors[field] = '';

            const control = form.get(field);
            if (control && control.dirty && !control.valid) {
                const messages = this.validationMessages[field];
                for (const key in control.errors) {
                    this.formErrors[field] += messages[key] + ' ';
                }
            }
        }

        if (!this.purchaserForm) { return; }
        form = this.purchaserForm;

        for (const field in this.formErrors) {
            // clear previous error message (if any)
            this.formErrors[field] = '';

            const control = form.get(field);
            if (control && control.dirty && !control.valid) {
                const messages = this.validationMessages[field];
                for (const key in control.errors) {
                    this.formErrors[field] += messages[key] + ' ';
                }
            }
        }

        //  Transfer values from the Form Model to the Data Model...
        this.recipientInfo = this.recipientForm.value;
        this.purchaserInfo = this.purchaserForm.value;
    }

    formErrors = {
        'recipientName': '',
        'recipientEmail': '',
        'giftMessage': '',
        'amount': '',
        'firstName': '',
        'lastName': '',
        'email': ''
    };

    validationMessages = {
        'recipientName': { 'invalid': 'Valid name is required.', 'maxlength': 'Valid name is required.', 'minlength': 'Valid name is required.'},
        'recipientEmail': { 'email': 'Valid email is required.' },
        'giftMessage': { 'maxlength': 'Max length 500 characters.' },
        'amount': { 'invalid': 'Amount should be $1-500.' },
        'firstName': { 'invalid': 'Valid first name is required.', 'maxlength': 'Valid first name is required.', 'minlength': 'Valid first name is required.' },
        'lastName': { 'invalid': 'Valid last name is required.', 'maxlength': 'Valid last name is required.', 'minlength': 'Valid last name is required.' },
        'email': { 'invalid': 'Valid email is required.' }
    };

    submitPayment() {
        this.inProgress = true;
        this.msg.error = false;
        this.msg.waiting = true;
        this.msg.message = 'Submitting credit card information.';
        this.show();

        this.svc.createToken(this.paymentInfo.creditCardInfo).then(
            (token) => {
                this.createGiftCard(token);
            },
            (err) => {
                this.inProgress = false;
                this.msg.error = true;
                this.msg.waiting = false;
                this.msg.message = 'Error submitting credit card info';
            });
    }

    private createGiftCard(token: string) {
        this.msg.message = 'Creating Gift Certificate.';
        let request = new GiftCertificateRequest();
        request.token = token;
        request.recipientName = this.recipientInfo.recipientName;
        request.recipientEmail = this.recipientInfo.recipientEmail;
        request.amount = this.recipientInfo.amount;
        request.giftMessage = this.recipientInfo.giftMessage;
        request.firstName = this.purchaserInfo.firstName;
        request.lastName = this.purchaserInfo.lastName;
        request.email = this.purchaserInfo.email;
        
        this.svc.processGiftCertificates(request).then(
            (data) => {
                this.inProgress = false;
                this.msg.error = false;
                this.msg.waiting = false;
                this.msg.message = 'Thank You!  Please look for your emailed receipt.';
            },
            (err) => {
                this.inProgress = false;
                this.msg.error = true;
                this.msg.waiting = false;
                this.msg.message = 'Error creating gift certificate.';
            });
    }

    public getTestCard() {
        this.purchaserInfo.firstName = "Test";
        this.purchaserInfo.lastName = "Name";
        this.purchaserInfo.email = "test.clgc@gmail.com";
        this.purchaserForm.reset(this.purchaserInfo);
        let data = new PaymentInfo();
        data.creditCardInfo = CardInfo.getTestCard();
        this.paymentInfo = data;
    }

    public getDeclinedCard() {
        this.purchaserInfo.firstName = "Test";
        this.purchaserInfo.lastName = "DeclinedCard";
        this.purchaserInfo.email = "test.clgc@gmail.com";
        this.purchaserForm.reset(this.purchaserInfo);
        let data = new PaymentInfo();
        data.creditCardInfo = CardInfo.getDeclinedCard();
        this.paymentInfo = data;
    }

    public getTestRecipient() {
        this.recipientInfo.recipientName = "Test Golfer";
        this.recipientInfo.recipientEmail = "tg@mail.com";
        this.recipientInfo.amount = 200;
        this.recipientInfo.giftMessage = "A lot of people have asked me 'why start now?'  I like to explain to them that back when I was younger we didn't just go buy a pound of chicken thighs at the local store. Or whatever happened to be on sale that week.  We started by sharpening a trusty blade.";
        this.recipientForm.reset(this.recipientInfo);
        let data = new PaymentInfo();
    }

    show() {
        $('.popup-with-form').click();
    }

    hide() {
        $('.mfp-close').click();
    }

    done() {
        this.hide();
        this.router.navigate(['/home']);
    }

    another() {
        this.hide();
        this.recipientInfo = new RecipientInfo();
        this.recipientForm.reset(this.recipientInfo);
        this.selectionComplete = false;
        this.paymentInfoComplete = false;
        this.inProgress = false;
    }
}
