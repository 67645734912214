import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/services';

@Component({
    selector: 'auth-redirect',
    template: `authorizing...`
})
export class AuthRedirectComponent implements OnInit {

    constructor(
        public auth: AuthService
    ) {
    }

    ngOnInit() {
        if (window.location.hash) {
            window.location.hash = decodeURIComponent(window.location.hash); // TODO remove?  this is a fix for break in ng5.2.8
            this.auth.completeAuthentication();
        }
    }
}