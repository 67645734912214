import { Component, OnInit, Input, EventEmitter, Output, forwardRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';


@Component({
  selector: 'phone-control',
  template: 
    `
    <form [formGroup]="form" novalidate>
        <input placeholder="Phone" class="form-control" formControlName="phoneNumber" type="tel">
    </form>
    `,
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => PhoneControlComponent),
        multi: true
    }, 
    {
        provide: NG_VALIDATORS, 
        useValue: (c: FormControl) => {
            let val = c.value;
            val = val.trim().replace(/^\+/, '');
            val = val.replace(/[^0-9]/g, '').slice(0,14);
            // val now cleared of everything but digits
            if (val.length != 10) {
                return { 'invalid': true };
            }
    
            return null;
        },
        multi: true
    }
  ]
})
export class PhoneControlComponent implements OnInit {
    @Output() valueChange = new EventEmitter<string>();
    private _model: string = '';
    set model(val: string) {
        if (this._model != val) {
            this._model = val;
            this.propagateChange(val);
            this.valueChange.emit(val);
            this.resetForm();
        }
    }

    get model(): string { return this._model; }

    public form: FormGroup;

    constructor(
        private fb: FormBuilder
    ) {
        this.createForm();

    }

    createForm() {
        this.form = this.fb.group({
            phoneNumber: ['']
        });
        this.form.get('phoneNumber').setValidators([PhoneValidators.validate10Digits]);

        this.form.valueChanges
            .subscribe(data => this.onValueChanged(data));

        this.onValueChanged(); // (re)set validation messages now
    }

    // ControlValueAccessor related....................
    propagateChange = (_: any) => { };

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() { }

    writeValue(value: any) {
        if (value !== undefined  && null != value) {
            this.model = value;
        }
    }
    //.................................................
    ngOnInit() {
    }

    ngOnChanges() {
        this.resetForm();
        this.form.markAsPristine();
    }

    private resetForm() {
        this.form.reset({phoneNumber: this.model});
    }

    onValueChanged(data?: any) {
        if (!this.form) { return; }
        const form = this.form;

        
        let phoneNumber = '';
        let val = this.form.controls['phoneNumber'].value;
        let _temp = val;
        val = val.trim().replace(/^\+/, '');
        val = val.replace(/[^0-9]/g, '').slice(0,10);
        let raw = val;
        
        let area, number;
        switch (val.length){
            case 1: 
            case 2:
            case 3: 
                area = val.slice(0,3);
                break;
            default: 
                area = val.slice(0,3);
                number = val.slice(3);
        }


        if (number){
            if (number.length > 3){
                number = number.slice(0,3) + '-' + number.slice(3,7);
            }
            else {
                number = number;
            }
            phoneNumber = ("(" + area + ") " + number).trim().slice(0,14);
        }
        else if (area) {
            phoneNumber = "(" + area;
        }

        if (this.form.controls['phoneNumber'].value != phoneNumber){
            this.form.controls['phoneNumber'].setValue(phoneNumber);
        }
        if (this.model != raw){
            this.writeValue(raw);
            this.form.controls['phoneNumber'].markAsDirty();
        }
    }
}

export class PhoneValidators {
    static validate10Digits = (c: FormControl) => {
        let val = c.value;
        val = val.trim().replace(/^\+/, '');
        val = val.replace(/[^0-9]/g, '').slice(0,14);
        // val now cleared of everything but digits
        if (val.length != 10) {
            return { 'invalid': true };
        }

        return null;
    }

}