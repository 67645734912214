import { Component, OnInit, OnChanges, ViewEncapsulation} from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from '@app/services';
import { OnlineBookingService } from '@app/services';
import { OBTViewModel, OnlineBookingRequest } from '@app/domain';

declare var $: any;

@Component({
    selector: 'app-tee-times',
    templateUrl: './teetimes.component.html',
    styleUrls: ['./teetimes.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TeeTimesComponent implements OnInit, OnChanges{
    public onlineBookingEnabled = true;
    public testData: any = 'none';
    public show: boolean;
    public showCalendar:  boolean = false;
    public maxPlayers: number = 0; 
    public numList: Array<number> = [];
    private _date: Date;
    public set date(val: Date) {
        if (val != this._date) {
            this._date = val;
            this.refresh();
        }
    }
    public get date(): Date {
        return this._date;
    }
    public minDate: Date;
    public maxDate: Date;

    public data: Array<OBTViewModel> = [];// [new Date(), new Date(), new Date()];
    public form: FormGroup;

    constructor(
        public obSvc: OnlineBookingService,
        private msg: MessageService,
        private fb: FormBuilder
    ) { 
        this.minDate = new Date();
        this.maxDate = new Date();
        this.maxDate.setDate(this.maxDate.getDate() + 6);

        this.form = this.fb.group({
            first: [''],
            last: [''],
            email: [''],
            phone: [''],
            numberOfSlots: ['']
        });
        this.form.get('first').setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(30)]);
        this.form.get('last').setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(30)]);
        this.form.get('phone').setValidators([Validators.required]);
        this.form.get('email').setValidators([Validators.required, Validators.email]);
        this.form.get('numberOfSlots').setValidators([Validators.required, Validators.min(1), Validators.max(4)]);
        this.form.valueChanges
            .subscribe(data => {
                let c = this.form.get('numberOfSlots');
                let n = c.value;
                if (n < 1 || n > this.maxPlayers){
                    c.setErrors({"invald number of players": true});
                }else{
                    c.setErrors(null);
                }
            });
    }
    
    ngOnChanges(e: any) {
        console.log(e);
    }

    ngOnInit() {
        this.date = new Date();
    }

    book(item: OBTViewModel){
        this.obSvc.request = new OnlineBookingRequest();
        this.maxPlayers = item.numberOfSlots;
        this.obSvc.request.timeStamp = item.timeStamp;
        this.obSvc.request.date = item.date;
        this.obSvc.request.first = '';
        this.obSvc.request.last = '';
        this.obSvc.request.email = '';
        this.obSvc.request.phone = '';
        // this.obSvc.request.first = 'testFirst';
        // this.obSvc.request.last = 'testLast';
        // this.obSvc.request.email = 'test@mail.com';
        // this.obSvc.request.phone = '0001234567';
        this.numList = [];

        for (let i = 1; i <= item.numberOfSlots; i++) {
            this.numList.push(i);
        } 
        this.form.reset(this.obSvc.request);
        $('#onlineBookingModal').appendTo("body").modal('show');
    }

    save() {
        $('#onlineBookingModal').modal('hide');
        this.msg.wait('Saving ...', 'Online Reservation');
        let data = this.form.value;
        let obr = new OnlineBookingRequest();
        obr.timeStamp = this.obSvc.request.timeStamp;
        obr.email = data.email;
        obr.first = data.first;
        obr.last = data.last;
        obr.phone = data.phone;
        obr.numberOfSlots = data.numberOfSlots;

        this.obSvc.post(obr).then(
            (data) => {
                this.msg.info("You're booked! Information has been emailed to you.", "Reservation Complete", ()=> {this.refresh();});
            }).catch((e) => {
                if (e.status == 409){
                    this.msg.info('Tee time no longer available.  Please refresh.', "There's been an issue!", ()=> {this.refresh();});
                }else{
                   this.msg.info(e.error, "There's been an issue!", ()=> {this.refresh()}); 
                }
            });
    }

    refresh() {
        this.msg.wait("Just a moment...", 'Loading tee times');
        this.obSvc.getOnlineTimes(this.date).then(data => {
            this.data = data;
            this.msg.hide();
        }).catch(
            e => {
                if (e && e.error == 'Online booking disabled.'){
                    this.onlineBookingEnabled = false;
                    console.error('ONLINE BOOKING DISABLED');
                }
                this.msg.hide();
            }
        )
    }

    today() {this.date = new Date();}

    test() {
        this.obSvc.getTest().then(
            (data) => {
                this.testData = data;
            }).catch((e) => {
                this.msg.info(e.error, "There's been an issue!", ()=> {this.refresh()}); 
            });
    }
}
