import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ContactRequest } from '../../shared/domain-classes';
import { ContactService } from '../../shared/services/contact.service';
declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
    public msg: any = { waiting: false, error: false, message: '' };
    public inProgress = false;
    public form: FormGroup;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private contact: ContactService
    ) {
        this.createForm();
    }

    ngOnDestroy() {
    }

    createForm() {
        this.form = this.fb.group({
            name: [''],
            email: [''],
            phone: [''],
            subject: [''],
            message: ['']
        });

        this.form.get('name').setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(50)]);
        this.form.get('email').setValidators([Validators.required, Validators.email]);
        this.form.get('subject').setValidators([Validators.max(50)]);
        this.form.get('message').setValidators([Validators.max(50)]);

        this.form.valueChanges
            .subscribe(data => this.onValueChanged(data));

        this.onValueChanged(); // (re)set validation messages now
    }

    ngOnInit() {
        $('.popup-with-form').magnificPopup({
            type: 'inline',
            preloader: false,
            focus: '#name',
            closeOnBgClick: true,

            // When elemened is focused, some mobile browsers in some cases zoom in
            // It looks not nice, so we disable it:
            callbacks: {
                beforeOpen: function () {
                    if ($(window).width() < 700) {
                        this.st.focus = false;
                    } else {
                        this.st.focus = '#name';
                    }
                }
            }
        });
        this.resetForm();
        // TODO focus the name input
    }


    ngOnChanges() {
        this.resetForm();
    }

    private resetForm() {
        this.form.reset(this.contact.request);
    }

    onValueChanged(data?: any) {
        if (!this.form) { return; }
        let form = this.form;

        for (const field in this.formErrors) {
            // clear previous error message (if any)
            this.formErrors[field] = '';

            const control = form.get(field);
            if (control && control.dirty && !control.valid) {
                const messages = this.validationMessages[field];
                for (const key in control.errors) {
                    this.formErrors[field] += messages[key] + ' ';
                }
            }
        }

        //  Transfer values from the Form Model to the Data Model...
        
    }

    formErrors = {
        'message': '',
        'subject': '',
        'name': '',
        'phone': '',
        'email': ''
    };

    validationMessages = {
        'name': { 'invalid': 'Valid name is required.', 'maxlength': 'Valid name is required.', 'minlength': 'Valid name is required.' },
        'email': { 'email': 'Valid email is required.' },
        'message': { 'maxlength': 'Max length 500 characters.' },
        'phone': { 'invalid': 'Valid first name is required.', 'maxlength': 'Valid first name is required.', 'minlength': 'Valid first name is required.' },
        'subject': { 'invalid': 'Valid last name is required.', 'maxlength': 'Valid last name is required.', 'minlength': 'Valid last name is required.' }
    };

    submit() {
        this.inProgress = true;
        this.msg.error = false;
        this.msg.waiting = true;
        this.msg.message = 'Submitting information.';
        this.show();

        if (!this.form.valid) { return; }

        this.contact.post(this.form.value).then(
            (data) => {
                this.inProgress = false;
                this.msg.error = false;
                this.msg.waiting = false;
                this.msg.message = 'Thank you for your interest.  You will hear from us soon!';
                this.contact.request = new ContactRequest();  // reset...
            },
            (err) => {
                this.inProgress = false;
                this.msg.error = true;
                this.msg.waiting = false;
                this.msg.message = 'Error submitting request';
            });
    }

    show() {
        $('.popup-with-form').click();
    }

    hide() {
        $('.mfp-close').click();
    }

    done() {
        this.hide();
        this.router.navigate(['/home']);
    }
}
