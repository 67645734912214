import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';


/** Pass untouched request through to the next request handler. */
@Injectable()
export class DatesInterceptor implements HttpInterceptor {
    private regexIso8601 = /^(\d{4}\-\d\d\-\d\d([tT][\d:\.]*)?)([zZ]|([+\-])(\d\d):?(\d\d))?$/;

    constructor(
        private dp: DatePipe
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let requestToForward = req.clone();
        this.convertDateToStringWithoutTimeZone(requestToForward);
        return next.handle(requestToForward).pipe(tap(
            (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.convertVariousProperties(event.body);
                }
            }, 
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                    // redirect to the login route
                    // or show a modal
                    }
                }
            }
        ));
    }

    private convertDateToStringWithoutTimeZone = (input) => {
        // Ignore things that aren't objects.
        if (typeof input !== "object") return input;
        for (var key in input) {
            if (!input.hasOwnProperty(key)) continue;
    
            var value = input[key];
            if (value instanceof Date) {
                input[key] = this.dp.transform(value, 'MMM dd yyyy HH:mm');
            }
            else if (typeof value === "object") {
                // Recurse into object
                this.convertDateToStringWithoutTimeZone(value);
            }
        }
    }

    private convertVariousProperties(input) {
        // Ignore things that aren't objects.
        if (typeof input !== "object") return input;
    
        var match;
        var value;
        for (var key in input) {
            if (!input.hasOwnProperty(key)) continue;
    
            value = input[key];
            
            // Check for string properties which look like dates.
            if (typeof value === "string" && (match = value.match(this.regexIso8601))) {
                //var milliseconds = Date.parse(match[0]);
                //if (!isNaN(milliseconds)) {
                //    input[key] = new Date(milliseconds);
                //}
                // NOTE match[0] includes the time zone.  match[1] should not
                input[key] = new Date(match[1]);
            } else if (typeof value === "number" && (value % 1 == 0) && (key.indexOf('Id')>0)) {
                input[key] = value.toString();
            }
            else if (key == 'NumHoles') {
                input[key] = value.toString();
            }
            else if (typeof value === "object") {
                // Recurse into object
                this.convertVariousProperties(value);
            }
        }
    }
}