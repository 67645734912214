import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@app/config';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {Router} from '@angular/router';
import { AuthService } from '@app/services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private auth: AuthService,
        @Inject(APP_CONFIG) private config: AppConfig,
        private router: Router
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let requestToForward = req;
        
        if (this.includeToken(req.url)) {
            let token = this.auth.getToken();
            if (null != token && token !== ""){
                let tokenValue = "Bearer " + token;
                requestToForward = req.clone({ setHeaders: { "Authorization": tokenValue } });
            }
        } else {
            console.debug("NO auth header added!");
            requestToForward = req;
        }

        //return next.handle(requestToForward);

        return next.handle(requestToForward).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              // do stuff with response if you want
            }
        }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
                localStorage.setItem("lastURL", this.router.url);
                this.auth.startAuthentication();
            }
        }
        }));
    }

    private includeToken(requestUrl){
        // check whether the url is hitting a secured part of the api (not google or whatever, or an anonymous endpoint)
        if (requestUrl.includes(this.config.baseUrl + 'onlinebooking')){
            return false;  // anonymous endpoint, leave off token
        }
        else if (requestUrl.includes(this.config.baseUrl)){
            return true;  // any other api endpoint, add the token
        }
        else{
            return false;  //else leave it off
        }
    }
}