import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'services-lessons',
  templateUrl: './lessons.component.html',
  styleUrls: ['./lessons.component.scss']
})
export class LessonsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
