import { Injectable } from '@angular/core';
import {
    CanActivate, CanLoad, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot, Route
} from '@angular/router';
import { AuthService } from '@app/services';

@Injectable()
export class EmployeeGuard implements CanActivate, CanLoad {
    constructor(
        private auth: AuthService,
        private router: Router
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.can(state.url);
    }

    canLoad(route: Route) {
        return this.can(route.path);
    }

    can(path: string): Promise<boolean> {
        return this.auth.loadUser().then((currentUser) => {
            if (this.auth.isLoggedIn()) {
                if (this.auth.current.isEmployee) {
                    return true;
                }
                else {
                    localStorage.setItem("lastURL", 'unauthorized');
                    this.router.navigateByUrl('unauthorized');
                    return false;
                }
            }
            else {
                localStorage.setItem("lastURL", path);
                this.auth.startAuthentication();
                return false;
            }
        }, ()=> {
            localStorage.setItem("lastURL", path);
            this.auth.startAuthentication();
            return false;
        });
    }
}