export {}

import {OnlineBookingTime} from '@app/internal/domain';

//don't redefine if it's already there
if (!Date.prototype.hasOwnProperty('toShortDate')) {
    Date.prototype.toShortDate = function(){
        return this.getMonth() + '/' + this.getDate() + '/' + this.getFullYear();
    }
}

if (!Date.prototype.hasOwnProperty('addDays')) {
    Date.prototype.addDays = function(n: number): Date {
        var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
        var retVal = new Date(this.getTime());
        retVal.setTime(this.getTime() + n * oneDay);  // nb - ignores day-light savings days (which don't have 24 hrs)
        return retVal;
    }
}

if (!Date.prototype.hasOwnProperty('midnight')) {
    Date.prototype.midnight = function(): Date {

        let retVal = new Date(this.getTime());
        retVal.setHours(0);
        retVal.setMinutes(0);
        retVal.setSeconds(0);
        retVal.setMilliseconds(0);
        return retVal;
    }
}

if (!Date.prototype.hasOwnProperty('beginningOfWeek')) {
    Date.prototype.beginningOfWeek = function(): Date {
        var retVal = new Date(this.getTime());
        retVal = retVal.midnight();
        return retVal.addDays(-retVal.getDay());
    }
}

if (!Date.prototype.hasOwnProperty('getTimeStr')) {
    Date.prototype.getTimeStr = function(): string {
        var str = "000"+ this.getMinutes();
        return this.getHours() + ':' + str.substring(str.length-2,str.length);
    }
}

if (!Date.prototype.hasOwnProperty('getTimeStamp')) {
    Date.prototype.getTimeStamp = function(): string {
        return this.getMonth()+1 + '/' + this.getDate() + '/' + this.getFullYear() + ' ' + this.getTimeStr();
    }
}

if (!Date.prototype.hasOwnProperty('numberOfTeeTimesElaspsed')) {
    Date.prototype.numberOfTeeTimesElaspsed = function(t2: Date): number {
        // compute the number of tee times from this time to the current time
        var t1 = new Date(this.getTime());
        t1.setDate(t2.getDate());
        t1.setMonth(t2.getMonth());
        t1.setFullYear(t2.getFullYear());  // set to the same day as the comparison time (actual date's not important here)
        
        // set the times to the beginning of the tee time they lie in
        // (account for the situation where the last tee time begins at 48 after and last 12 minutes rather than 8)
        t1.setSeconds(0);t2.setSeconds(0);
        var m = Math.floor(t2.getMinutes()/8)*8;
        t2.setMinutes(m >= 48 ? 48 : m);
        m = Math.floor(t1.getMinutes()/8)*8;
        t1.setMinutes(m >= 48 ? 48 : m);
        
        // increment the initial time until it exceeds the input time, counting teetimes along the way
        var numTeeTimes = -1;
        while(t2.getTime()-t1.getTime() >=0){
            numTeeTimes++;
            var increment = t1.getMinutes()>=48 ? 12 : 8;
            t1.setMinutes(t1.getMinutes()+increment);
        }

        return numTeeTimes;
    }
}

if (!Date.prototype.hasOwnProperty('isAvailable')) {
    Date.prototype.isAvailable = function(times: OnlineBookingTime[]): boolean{
        let h = this.getHours();
        let m = this.getMinutes();
        for (var ii = 0; ii < times.length; ii++) {
            if (times[ii].time.getMinutes() == m && times[ii].time.getHours() == h) {
                return times[ii].isAvailable;
            }
        }
        return false;
    }
}

Array.prototype.numOpenSlots = function (){
    // return number from 0-4 of how many open slots exist for this tee sheet Row (exlude the 'player 5' slot)
    var numSlots = 0;
    for (var ii = 1; ii <=4 ; ii++) {
        numSlots = this[ii].subType == 'tee-open' ? numSlots + 1 : numSlots;   
    }
    return numSlots;
}


Array.prototype.sortBySlotNo = function (){
    this.sort(function(a,b){return a.SlotNo - b.SlotNo})
}

Array.prototype.reservationSort = function (){
    // first sort by tee time (ASC) then by number of players (ASC)
    this.sort(function(a,b){
        var nTees = b.start.NumberOfTeeTimesElaspsed(a.start);
        return nTees == 0 ? a.numPlayers-b.numPlayers : nTees;  
    });
}

Array.prototype.findIdByDescription = function (description) {
    for (var ii = 0; ii < this.length; ii++) {
        if (typeof this[ii] === 'object' && this[ii].description == description) {
            return this[ii].id;
        }
    }
};

Array.prototype.findById = function (id) {
    for (var ii = 0; ii < this.length; ii++) {
        if (typeof this[ii] === 'object' && this[ii].Id.toString() == id.toString()) {
            return this[ii];
        }
    }
};

Array.prototype.findByTime = function (date) {
    var retVal = [];
    for (var ii = 0; ii < this.length; ii++) {
        if (typeof this[ii] === 'object' && this[ii].start.getHours() == date.getHours() && this[ii].start.getMinutes() == date.getMinutes()) {
            retVal.push(this[ii]);
        }
    }
    return retVal;
};

Array.prototype.containsRole = function (roleDescription) {
    for (var ii = 0; ii < this.length; ii++) {
        if (this[ii] == roleDescription) { return true;}
    }
    return false;
};

Array.prototype.injectArray = function( idx, arr ) {
    /*
    Example:  this = [1,2,3,4,5];arr = [21,22];idx = 2; returns [1,2,21,22,3,4,5]
     */
    return this.slice( 0, idx ).concat( arr ).concat( this.slice( idx ) );
};

Array.prototype.init = function(startValue, step, steps ) {
    var retVal = [];
    var val = startValue;
    for (var ii = 1; ii <= steps ; ii++){
        retVal.push(val);
        val = val + step;
    }
    return retVal;
};

Array.prototype.range = function (start, stop) {
    var retVal = [];
    for (var ii = start; ii <= stop ; ii++) {
        retVal.push(ii);
    }
    return retVal;
};

Array.prototype.getById = function(id){
    for (var ii = 0; ii < this.length; ii++) {
        if (typeof this[ii] === 'object' && this[ii].Id == id) {
            return this[ii];
        }
    }
};
    
Array.prototype.getNdxById = function(id){
    for (var ii = 0; ii < this.length; ii++) {
        if (typeof this[ii] === 'object' && this[ii].Id == id) {
            return ii;
        }
    }
};
    
Array.prototype.getNextId = function(){
    var nextId = -1;
    for (var ii = 0; ii < this.length; ii++) {
        if (typeof this[ii] === 'object') {
            nextId = this[ii].Id > nextId ? this[ii].Id : nextId;
        }
    }
    return nextId + 1;
};


Array.prototype.getSqueezeNdx = function(squeezeTime: Date){
    // intended for a teeSheet array.  find the ndx when the squeeze should be inserted.
    for (var ii = 0; ii < this.length; ii++) {
        var time = this[ii][0].label;  // not sure why I named it this but that's where the time for each teetime is stored
        if (time.getTime() - squeezeTime.getTime() > 0){return ii-1;}
    }
}