import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { APP_CONFIG, AppConfig } from '../../app-config.module';
import { } from '../domain-classes';

@Injectable()
export class LogService {
    constructor(
        private http: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig
    ) { }

    log(message: string): void {
        
        return;
        //return this.http.post(this.config.baseUrl + 'values', { message: message })
        //    .toPromise()
        //    .then(resp => {
        //        return resp as any;
        //    })
        //    .catch(this.handlePromiseError);
    }

    private handlePromiseError(e: any) {
        return Promise.reject(JSON.stringify(e));
    }
}
