import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { BsDatepickerModule} from 'ngx-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { AppSharedModule } from '../shared/app-shared.module';
import { HomeComponent } from './home/home.component';
import { PublicComponent } from './public.component';
import { RouterModule } from '@angular/router';
import { EventsComponent } from './events/events.component';
import { ContactComponent } from './contact/contact.component';
import { MembershipComponent } from './membership/membership.component';
import { NewsComponent } from './news/news.component';
import { CourseDetailsComponent } from './course-details/course-details.component';
import { ServicesComponent } from './services/services.component';
import { TeeTimesComponent } from './teetimes/teetimes.component';
import { TeeTimeBannerComponent } from './teetime-banner/teetime-banner.component';
import { LessonsComponent } from './services/lessons/lessons.component';
import { OutingsComponent } from './services/outings/outings.component';
import { MerchandiseComponent } from './services/merchandise/merchandise.component';
import { ClgaComponent } from './services/clga/clga.component';
import { DiscountsComponent } from './services/discounts/discounts.component';
import { RatesComponent } from './course-details/rates/rates.component';
import { GiftCertificatesComponent } from './gift-certificates/gift-certificates.component';

@NgModule({
    imports: [
        CommonModule,
        AppSharedModule,
        RouterModule,
        NgxDatatableModule,
        BsDatepickerModule
    ],
    declarations: [
        HomeComponent, 
        PublicComponent, 
        EventsComponent,
        ContactComponent,
        MembershipComponent,
        NewsComponent,
        CourseDetailsComponent,
        ServicesComponent,
        TeeTimesComponent,
        TeeTimeBannerComponent,
        LessonsComponent,
        OutingsComponent,
        MerchandiseComponent,
        ClgaComponent,
        DiscountsComponent,
        RatesComponent,
        GiftCertificatesComponent
    ]
})
export class PublicModule { }
