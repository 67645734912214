import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'unauthorized',
    template: 
    `
        <h2>
        Unauthorized
    </h2>
    <div>
    <!--<button type="button" class="btn btn-primary" (click)="login()">Login</button>
    <button type="button" class="btn btn-default" (click)="goback()">Back</button>-->
    </div>
    `
})

export class UnauthorizedComponent implements OnInit {

    public message: string;
    public values: any[];

    constructor() {
        this.message = 'UnauthorizedComponent constructor';
    }

    ngOnInit() {
    }

    login() {
      //this.service.startSigninMainWindow();
    }

    goback() {
        //this.location.back();
    }
}